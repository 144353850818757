<script>
export default {
    name: "VAssetsDetails",
    props: {
        stats: {},
    },
    data() {
        return {
            showWind: true,
        }
    },
    computed: {
        currentFeed: function () {
            if (this.showWind) {
                return this.stats.wind;
            } else {
                return this.stats.solar;
            }
        }
    }

};
</script>

<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="btn-group mb-3" role="group">
                    <base-button
                        @click="showWind = true"
                        type="primary"
                        size="sm"
                        :outline="true"
                        :class="{ active: showWind }"
                        >{{ $t("reports.wind") }}</base-button
                    >
                    <base-button
                        type="primary"
                        @click="showWind = false"
                        size="sm"
                        :outline="true"
                        :class="{ active: !showWind }"
                        >{{ $t("reports.solar") }}</base-button
                    >
                </div>
            </div>
            <div class="col-12">
                <div class="table-responsive">
                    <v-table class="table table-sm table-striped">
                        <v-thead>
                            <v-tr>
                                <v-th>{{ $t("reports.name") }}</v-th>
                                <v-th class="text-right">{{
                                    $t("reports.count")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("reports.avg_rating_per_unit")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("reports.total_rating")
                                }}</v-th>
                                <v-th class="text-right">{{
                                    $t("reports.value")
                                }}</v-th>
                            </v-tr>
                        </v-thead>
                        <v-tbody>
                            <v-tr v-for="(a, index) in currentFeed" :key="index">
                                <v-td>{{ a.name }}</v-td>
                                <v-td class="text-right">{{ a.count }}</v-td>
                                <v-td class="text-right"
                                    >{{ $n(a.rating/a.count) }} MW</v-td
                                >
                                <v-td class="text-right"
                                    >{{ $n(a.rating) }} MW</v-td
                                >
                                <v-td class="text-right">{{
                                    $n(a.value, {
                                        style: "currency",
                                        currency: a.value_currency,
                                        currencyDisplay: "code",
                                    })
                                }}</v-td>
                            </v-tr>
                        </v-tbody>
                    </v-table>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
</style>