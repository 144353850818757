import i18n from '@/i18n';

export default function (money, currency) {

    let abbreviatedLetter = Math.abs(Number(money)) >= 1.0e+6 ?
        "M" :
        "";

    let abbreviatedNumber = Math.abs(Number(money)) >= 1.0e+6 ?
        Math.abs(Number(money)) / 1.0e+6 :        
        Math.abs(Number(money));

    let formatted = i18n.n(abbreviatedNumber, {
        style: "currency",
        currency: currency,
        currencyDisplay: "code"
    });

    // get the number out, then replace the number with our modified number +_ abbr letter M/K
    let justNumber = formatted.replace(currency, "").trim();

    return formatted.replace(justNumber, justNumber + abbreviatedLetter);
}