var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(false)?_c('pre',[_vm._v(_vm._s(_vm.stats))]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-4"},[_c('inner-dash-card',{attrs:{"title":_vm.$t('reports.total_contract_turnover'),"icon":"far fa-file-signature"}},[_c('template',{slot:"default"},[_vm._v(_vm._s(_vm.ch(_vm.stats.total, _vm.stats.currency)))]),_c('template',{slot:"footer"},[_vm._v(" "+_vm._s(_vm.$t("reports.billable_contract_amount_in_Y", { Y: _vm.stats.year }))+" ")])],2)],1),_c('div',{staticClass:"col-12 col-md-6 col-lg-4"},[_c('inner-dash-card',{attrs:{"title":_vm.$t('reports.fixed_contracts'),"icon":"far fa-file-signature"}},[_c('template',{slot:"default"},[_vm._v(_vm._s(_vm.ch(_vm.stats.contract_type[1], _vm.stats.currency)))]),_c('template',{slot:"footer"},[_c('i',{staticClass:"far fa-chart-pie-alt"}),_vm._v(" "+_vm._s(_vm.$t("reports.N_of_total_X", { N: _vm.i18perc(_vm.stats.contract_type[1], _vm.stats.total), X: _vm.ch(_vm.stats.total, _vm.stats.currency) }))+" ")])],2)],1),_c('div',{staticClass:"col-12 col-md-6 col-lg-4"},[_c('inner-dash-card',{attrs:{"title":_vm.$t('reports.variable_contracts'),"icon":"far fa-file-signature"}},[_c('template',{slot:"default"},[_vm._v(_vm._s(_vm.ch(_vm.stats.contract_type[2], _vm.stats.currency)))]),_c('template',{slot:"footer"},[_c('i',{staticClass:"far fa-chart-pie-alt"}),_vm._v(" "+_vm._s(_vm.$t("reports.N_of_total_X", { N: _vm.i18perc(_vm.stats.contract_type[2], _vm.stats.total), X: _vm.ch(_vm.stats.total, _vm.stats.currency) }))+" ")])],2)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h3',[_vm._v(_vm._s(_vm.$t("reports.income_by_asset_type")))]),_c('div',{staticStyle:{"display":"block","height":"350px"}},[_c('apexchart',{attrs:{"height":"100%","options":{
                        chart: {
                            type: 'donut'
                        },
                        labels: [
                            _vm.$t('reports.contract_income_wind') +
                                ': ' +
                                _vm.$n(_vm.stats.asset_type[1], {
                                    style: 'currency',
                                    currency: 'DKK',
                                    currencyDisplay: 'code'
                                }),
                            _vm.$t('reports.contract_income_solar') +
                                ': ' +
                                _vm.$n(_vm.stats.asset_type[2], {
                                    style: 'currency',
                                    currency: 'DKK',
                                    currencyDisplay: 'code'
                                })
                        ],
                        yaxis: {
                            labels: {
                                formatter: function(value) {
                                    return _vm.ch(value, _vm.stats.currency);
                                }
                            }
                        },
                        responsive: [
                            {
                                breakpoint: 480,
                                options: {
                                    chart: {
                                        width: '100%'
                                    },
                                    legend: {
                                        position: 'bottom'
                                    }
                                }
                            }
                        ]
                    },"series":Object.values(_vm.stats.asset_type)}})],1)]),_c('div',{staticClass:"col-6"},[_c('h3',[_vm._v(_vm._s(_vm.$t("reports.income_by_quarter")))]),_c('div',{staticStyle:{"display":"block","height":"350px"}},[_c('apexchart',{attrs:{"height":"100%","options":{
                        chart: {
                            type: 'donut'
                        },
                        labels: [
                            _vm.$t('reports.quarter_1') +
                                ': ' +
                                _vm.$n(_vm.stats.qs[1], {
                                    style: 'currency',
                                    currency: 'DKK',
                                    currencyDisplay: 'code'
                                }),
                            _vm.$t('reports.quarter_2') +
                                ': ' +
                                _vm.$n(_vm.stats.qs[2], {
                                    style: 'currency',
                                    currency: 'DKK',
                                    currencyDisplay: 'code'
                                }),
                            _vm.$t('reports.quarter_3') +
                                ': ' +
                                _vm.$n(_vm.stats.qs[3], {
                                    style: 'currency',
                                    currency: 'DKK',
                                    currencyDisplay: 'code'
                                }),
                            _vm.$t('reports.quarter_4') +
                                ': ' +
                                _vm.$n(_vm.stats.qs[4], {
                                    style: 'currency',
                                    currency: 'DKK',
                                    currencyDisplay: 'code'
                                })
                        ],
                        yaxis: {
                            labels: {
                                formatter: function(value) {
                                    return _vm.ch(value, _vm.stats.currency);
                                }
                            }
                        },
                        responsive: [
                            {
                                breakpoint: 480,
                                options: {
                                    chart: {
                                        width: '100%'
                                    },
                                    legend: {
                                        position: 'bottom'
                                    }
                                }
                            }
                        ]
                    },"series":Object.values(_vm.stats.qs)}})],1)])]),(false)?_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-6"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("reports.yearly_contract_income_in_home_currency"))+" ")]),_c('ul',{staticClass:"list-group mt-4"},[_c('li',{staticClass:"list-group-item"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-bold"},[_vm._v(" "+_vm._s(_vm.$t("report.currency"))+" ")]),_c('div',{staticClass:"col text-right text-bold"},[_vm._v(" "+_vm._s(_vm.$t("report.amount_in_currency"))+" ")]),_c('div',{staticClass:"col text-right text-bold"},[_vm._v(" "+_vm._s(_vm.$t("report.amount_converted_to_X", { X: _vm.currency }))+" ")])])]),_vm._l((_vm.stats.currencies),function(amount,currency){return _c('li',{key:currency,staticClass:"list-group-item"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('i',{staticClass:"far fa-coins"}),_vm._v(" "+_vm._s(currency)+" ")]),_c('div',{staticClass:"col text-right"},[_vm._v(" "+_vm._s(_vm.$n(amount, { style: "currency", currency: currency, currencyDisplay: "symbol" }))+" ")]),_c('div',{staticClass:"col text-right"},[_vm._v(" "+_vm._s(_vm.$n(_vm.stats.currencies_conv[currency], { style: "currency", currency: _vm.stats.currency, currencyDisplay: "code" }))+" ")])])])})],2),_c('h4',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t("reports.distribution_by_currency"))+" ")]),_c('div',{staticClass:"mt-2",staticStyle:{"display":"block","height":"240px"}},[_c('apexchart',{attrs:{"height":"100%","options":{
                        chart: {
                            type: 'donut'
                        },
                        labels: Object.keys(_vm.stats.currencies_conv),
                        yaxis: {
                            labels: {
                                formatter: function(value) {
                                    return _vm.ch(value, _vm.stats.currency);
                                }
                            }
                        },
                        responsive: [
                            {
                                breakpoint: 480,
                                options: {
                                    chart: {
                                        width: '100%'
                                    },
                                    legend: {
                                        position: 'bottom'
                                    }
                                }
                            }
                        ]
                    },"series":Object.values(_vm.stats.currencies_conv)}})],1)]),_c('div',{staticClass:"col-6"},[_c('h3',[_vm._v(_vm._s(_vm.$t("reports.yearly_invoice_plan")))]),_c('div',{staticClass:"table-responsive"},[_c('v-table',{staticClass:"table table-sm table-striped"},[_c('v-thead',[_c('v-tr',[_c('v-th',[_vm._v(_vm._s(_vm.$t("reports.invoice_date")))]),_c('v-th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t("reports.amount")))])],1)],1),_c('v-tbody',_vm._l((_vm.stats.days),function(amount,date){return _c('v-tr',{key:date},[_c('v-td',[_vm._v(_vm._s(_vm.$d(new Date(date), "short")))]),_c('v-td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$n(amount, { style: "currency", currency: _vm.stats.currency, currencyDisplay: "code" })))])],1)}),1)],1)],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }