<script>
import VContractStatForm from "@/components/Forms/VContractStatForm";
import ContractStatService from "@/services/ContractStatService";

export default {
    props: {
        src_group: {}
    },
    components: { VContractStatForm },
    data() {
        return {
            loading: true,
            stats: [],
            showModal: false,
            context: { empty: true },
            groups: {},
            names: {
                'faa-de': "Fordeling af aktiver DE",
                'faa-meg': "Fordeling af aktiver MEG",
                'seg-de-meg': "Segment MEG/DE",
                'seg-de-ins': "Segment Institutionel/DE",
                'seg-de-prv': "Segment Private/DE",
                'seg-meg-meg': "Segment MEG/MEG",
                'seg-meg-ins': "Segment Institutionel/MEG",
                'seg-meg-prv': "Segment Private/MEG",
            }

        };
    },
    computed: {
        resolvedGroup() {
            if (this.src_group in this.names) {
                return this.names[this.src_group];
            }         else {
                return "...";
            }
        }
    },
    created() {
        
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                this.groups = await ContractStatService.GET_groups();
                const { data } = await ContractStatService.GET(this.src_group);
                this.stats = data.data;
            } catch (e) {
                console.log("fd e", e);
            }

            this.loading = false;
        },
        closeRefresh() {
            this.showModal = false;
            this.fetchData();
        },
        setContext(ctx) {
            this.context = ctx;
            this.showModal = true;
        }
    }
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12 d-flex">
                    <h1>{{ $t("reports.source_data_for_statistics") }}</h1>
                </div>

                <!-- Top table DK -->
                <div class="col-12">
                    <card body-classes="px-0">
                        <div class="px-4 mb-4">
                            <router-link :to="{name: 'NewContractsReports'}"><i class="fa fa-chevron-left"></i> {{$t('stat_data.back_to_report')}}</router-link>
                        </div>
                        <div
                            class="d-flex align-items-center justify-content-between px-4 mb-4"
                        >
                            <h3 class="mb-0 pb-0">
                                {{
                                    $t("stat_data.group_GROUP", {
                                        GROUP: resolvedGroup
                                    })
                                }}
                            </h3>
                            <base-button
                                @click="setContext({ empty: true })"
                                size="sm"
                                type="primary"
                                outline
                                ><i class="fa fa-plus"></i>
                                {{ $t("stat_data.add_entry") }}</base-button
                            >
                        </div>

                        <v-loader v-if="loading" :loading="loading" />

                        <template v-else-if="stats.length > 0">
                            <v-table class="table table-sm">
                                <v-thead>
                                    <v-tr>
                                        <v-th>{{
                                            $t("stat_data.hdr_date")
                                        }}</v-th>
                                        <v-th class="text-right`">{{
                                            $t("stat_data.hdr_count_wind")
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t("stat_data.hdr_power_wind")
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t("stat_data.hdr_value_wind")
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t("stat_data.hdr_count_solar")
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t("stat_data.hdr_power_solar")
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t("stat_data.hdr_value_solar")
                                        }}</v-th>
                                        <v-th></v-th>
                                    </v-tr>
                                </v-thead>
                                <v-tbody>
                                    <v-tr v-for="d in stats" :key="d.id">
                                        <v-td>{{
                                            $d(new Date(d.entry_date), "short")
                                        }}</v-td>
                                        <v-td class="text-right">{{
                                            $n(d.count_wind)
                                        }}</v-td>
                                        <v-td class="text-right"
                                            >{{ $n(d.power_wind) }} MW</v-td
                                        >
                                        <v-td class="text-right">{{
                                            $n(d.value_wind, {
                                                style: "currency",
                                                currency: "DKK",
                                                currencyDisplay: "code"
                                            })
                                        }}</v-td>
                                        <v-td class="text-right">{{
                                            $n(d.count_solar)
                                        }}</v-td>
                                        <v-td class="text-right">{{
                                            $n(d.power_solar)
                                        }} MW</v-td>
                                        <v-td class="text-right">{{
                                            $n(d.value_solar, {
                                                style: "currency",
                                                currency: "DKK",
                                                currencyDisplay: "code"
                                            })
                                        }}</v-td>
                                        <v-td class="text-right">
                                            <i class="fa fa-pencil fa-lg fa-fw m-click text-primary" @click="setContext(d)"></i>
                                        </v-td>
                                    </v-tr>
                                </v-tbody>
                            </v-table>
                        </template>
                        <div v-else class="mx-4 alert alert-warning">{{ $t('stat_data.no_data_entries') }}</div>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-contract-stat-form
                :context="context"
                :stat-group="src_group"
                v-if="showModal"
                @close="showModal = false"
                @close-refresh="closeRefresh"
            />
        </portal>
    </div>
</template>
