<script>
import ContractStatService from "@/services/ContractStatService";
import VTopSegmentTable from "@/views/Reports/Contracts/components/VTopSegmentTable";

export default {
    props: {
        cs: {}
    },
    components: { VTopSegmentTable },
    data() {
        return {
            slugs: [
                "seg-de-meg",
                "seg-de-ins",
                "seg-de-prv",
                "seg-meg-meg",
                "seg-meg-ins",
                "seg-meg-prv"
            ],
            loading: true,
            data: {},
            dates: {}
        };
    },
    async created() {
        this.loading = true;
        await this.fetchData();
        this.loading = false;
    },
    methods: {
        /**
         *  seg-de-meg
            seg-de-ins
            seg-de-prv
            seg-meg-meg
            seg-meg-ins
            seg-meg-prv
         */
        async fetchData() {
            for (const slug of this.slugs) {
                try {
                    console.log("Fetching ... ", slug);
                    const { data } = await ContractStatService.GET_latest(slug);
                    this.data[slug] = data.data;
                    this.dates[slug] = this.$d(
                        new Date(data.data.entry_date),
                        "short"
                    );
                } catch (e) {
                    this.data[slug] = [];
                    this.dates[slug] = "---";
                }
            }
        }
    }
};
</script>

<template>
    <!-- Top tables per segment DK DE MEG -->
    <div class="col-12">
        <card body-classes="px-0">
            <!-- .DK -->
            <h3 class="px-4 mb-4">
                {{
                    $t("reports.active_distribution_per_segment_in_COUNTRY", {
                        COUNTRY: "DK"
                    })
                }}
            </h3>
            <div class="px-4">
                <v-top-segment-table :data="cs.asset_segments.segmented" />
            </div>

            <!-- .DE -->
            <div
                class="d-flex align-items-start justify-content-between px-4 mt-5"
            >
                <h3 class="mb-0">
                    {{
                        $t(
                            "reports.active_distribution_per_segment_in_COUNTRY",
                            {
                                COUNTRY: "DE"
                            }
                        )
                    }}
                    <div class="small text-muted">
                        <template v-if="loading === false">
                            <ul class="list-inline">
                                <li>MEG: {{ dates["seg-de-meg"] }}</li>
                                <li>INST: {{ dates["seg-de-ins"] }}</li>
                                <li>PRIV: {{ dates["seg-de-prv"] }}</li>
                            </ul>
                        </template>
                    </div>
                </h3>
                <div>
                    <base-button
                        type="link"
                        :outline="true"
                        size="sm"
                        @click="
                            $router.push({
                                name: 'ContractsReportsData',
                                params: { src_group: 'seg-de-meg' }
                            })
                        "
                        ><i class="fa fa-plus"></i>
                        {{
                            $t("reports.add_SLUGNAME", { SLUGNAME: "MEG" })
                        }}</base-button
                    >

                    <base-button
                        type="link"
                        :outline="true"
                        size="sm"
                        @click="
                            $router.push({
                                name: 'ContractsReportsData',
                                params: { src_group: 'seg-de-prv' }
                            })
                        "
                        ><i class="fa fa-plus"></i>
                        {{
                            $t("reports.add_SLUGNAME", { SLUGNAME: "Private" })
                        }}</base-button
                    >
                    <base-button
                        type="link"
                        :outline="true"
                        size="sm"
                        @click="
                            $router.push({
                                name: 'ContractsReportsData',
                                params: { src_group: 'seg-de-ins' }
                            })
                        "
                        ><i class="fa fa-plus"></i>
                        {{
                            $t("reports.add_SLUGNAME", {
                                SLUGNAME: "Institutional"
                            })
                        }}</base-button
                    >
                </div>
            </div>

            <div class="px-4" v-if="loading === false">
                <template
                    v-if="
                        Object.entries(data['seg-de-meg']).length > 0 &&
                            Object.entries(data['seg-de-ins']).length > 0 &&
                            Object.entries(data['seg-de-prv']).length > 0
                    "
                >
                    <v-top-segment-table
                        :data="{
                            1: {
                                wind_count: data['seg-de-meg'].count_wind,
                                solar_count: data['seg-de-meg'].count_solar,
                                wind_rating: data['seg-de-meg'].power_wind,
                                solar_rating: data['seg-de-meg'].power_solar,
                                wind_value: data['seg-de-meg'].value_wind,
                                solar_value: data['seg-de-meg'].value_solar
                            },
                            3: {
                                wind_count: data['seg-de-ins'].count_wind,
                                solar_count: data['seg-de-ins'].count_solar,
                                wind_rating: data['seg-de-ins'].power_wind,
                                solar_rating: data['seg-de-ins'].power_solar,
                                wind_value: data['seg-de-ins'].value_wind,
                                solar_value: data['seg-de-ins'].value_solar
                            },
                            2: {
                                wind_count: data['seg-de-prv'].count_wind,
                                solar_count: data['seg-de-prv'].count_solar,
                                wind_rating: data['seg-de-prv'].power_wind,
                                solar_rating: data['seg-de-prv'].power_solar,
                                wind_value: data['seg-de-prv'].value_wind,
                                solar_value: data['seg-de-prv'].value_solar
                            }
                        }"
                    />
                </template>
                <div class="alert alert-warning mt-4" v-else>
                    {{ $t("reports.no_data_add_first") }}
                </div>
            </div>

            <!-- MEG -->
            <div
                class="d-flex align-items-start justify-content-between px-4 mt-5"
            >
                <h3 class="mb-0">
                    {{
                        $t(
                            "reports.active_distribution_per_segment_in_COUNTRY",
                            {
                                COUNTRY: "MEG"
                            }
                        )
                    }}
                    <div class="small text-muted">
                        <template v-if="loading === false">
                            <ul class="list-inline">
                                <li>MEG: {{ dates["seg-meg-meg"] }}</li>
                                <li>INST: {{ dates["seg-meg-ins"] }}</li>
                                <li>PRIV: {{ dates["seg-meg-prv"] }}</li>
                            </ul>
                        </template>
                    </div>
                </h3>
                <div>
                    <base-button
                        type="link"
                        :outline="true"
                        size="sm"
                        @click="
                            $router.push({
                                name: 'ContractsReportsData',
                                params: { src_group: 'seg-meg-meg' }
                            })
                        "
                        ><i class="fa fa-plus"></i>
                        {{
                            $t("reports.add_SLUGNAME", { SLUGNAME: "MEG" })
                        }}</base-button
                    >

                    <base-button
                        type="link"
                        :outline="true"
                        size="sm"
                        @click="
                            $router.push({
                                name: 'ContractsReportsData',
                                params: { src_group: 'seg-meg-prv' }
                            })
                        "
                        ><i class="fa fa-plus"></i>
                        {{
                            $t("reports.add_SLUGNAME", { SLUGNAME: "Private" })
                        }}</base-button
                    >
                    <base-button
                        type="link"
                        :outline="true"
                        size="sm"
                        @click="
                            $router.push({
                                name: 'ContractsReportsData',
                                params: { src_group: 'seg-meg-ins' }
                            })
                        "
                        ><i class="fa fa-plus"></i>
                        {{
                            $t("reports.add_SLUGNAME", {
                                SLUGNAME: "Institutional"
                            })
                        }}</base-button
                    >
                </div>
            </div>

            <div class="px-4" v-if="loading === false">
                <template
                    v-if="
                        Object.entries(data['seg-meg-meg']).length > 0 &&
                            Object.entries(data['seg-meg-ins']).length > 0 &&
                            Object.entries(data['seg-meg-prv']).length > 0
                    "
                >
                    <v-top-segment-table
                        :data="{
                            1: {
                                wind_count: data['seg-meg-meg'].count_wind,
                                solar_count: data['seg-meg-meg'].count_solar,
                                wind_rating: data['seg-meg-meg'].power_wind,
                                solar_rating: data['seg-meg-meg'].power_solar,
                                wind_value: data['seg-meg-meg'].value_wind,
                                solar_value: data['seg-meg-meg'].value_solar
                            },
                            2: {
                                wind_count: data['seg-meg-prv'].count_wind,
                                solar_count: data['seg-meg-prv'].count_solar,
                                wind_rating: data['seg-meg-prv'].power_wind,
                                solar_rating: data['seg-meg-prv'].power_solar,
                                wind_value: data['seg-meg-prv'].value_wind,
                                solar_value: data['seg-meg-prv'].value_solar
                            },
                            3: {
                                wind_count: data['seg-meg-ins'].count_wind,
                                solar_count: data['seg-meg-ins'].count_solar,
                                wind_rating: data['seg-meg-ins'].power_wind,
                                solar_rating: data['seg-meg-ins'].power_solar,
                                wind_value: data['seg-meg-ins'].value_wind,
                                solar_value: data['seg-meg-ins'].value_solar
                            }
                        }"
                    />
                </template>
                <div class="alert alert-warning mt-4" v-else>
                    {{ $t("reports.no_data_add_first") }}
                </div>
            </div>
        </card>
    </div>
</template>
