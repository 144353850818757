<script>
import Http from "@/libraries/Http";

export default {
    name: "VStatExport",
    methods: {
        download() {
            Http({
                url: "/v1/contracts/export-data",
                method: "GET",
                responseType: "text/csv",
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(
                    new Blob([response.data], {type: 'text/csv'})
                );
                var fileLink = document.createElement("a");

                fileLink.href = fileURL;
                fileLink.setAttribute("download", "contract-report.csv");
                document.body.appendChild(fileLink);

                fileLink.click();
            });            
        },
    },
};
</script>

<template>
    <div>        
        <base-button @click="download" type="primary"><i class="far fa-file"></i> {{$t('report.download_csv_report')}}</base-button>        
    </div>
</template>

<style>
</style>