<script>
import InnerDashCard from "@/components/Cards/InnerDashCard";
import CurrencyHumanizer from "@/util/MillionHumanizer";
import NumberHumanizer from "@/util/NumberHumanizer";
import QMath from "@/libraries/QMath";
import MGConsts from "@/mixins/MGConsts";

export default {
    name: "VContractStats",
    props: {
        stats: {},
        cstats: {
            description: "Contract stats"
        }
    },
    mixins: [MGConsts],
    components: { InnerDashCard },
    computed: {
        translatedCategories: function() {
            let data = [];
            this.stats.freqs.categories.forEach((v, k) => {
                data.push(this.mgPaymentFrequencyHash[v]);
            });

            return data;
        },
        statBoxClass() {
            return "col-4";
        }
    },
    methods: {
        ch: CurrencyHumanizer,
        nh: NumberHumanizer,
        perc: QMath.perc,
        i18perc: QMath.i18perc
    }
};
</script>

<template>
    <div>
        <pre v-if="false">{{ stats }}</pre>

        <div class="row">
            <!-- Box 1 -->
            <div :class="statBoxClass">
                <inner-dash-card
                    :title="$t('reports.total_contracts')"
                    icon="far fa-file-signature"
                >
                    <template slot="default">{{ stats.count }}</template>
                    <template slot="footer">
                        {{ $t("reports.currently_active_contracts") }}
                    </template>
                </inner-dash-card>
            </div>

            <!-- Box 2 -->
            <div :class="statBoxClass">
                <inner-dash-card
                    :title="$t('reports.fixed_contracts')"
                    icon="far fa-file-signature"
                >
                    <template slot="default">{{ stats.type_fixed }}</template>
                    <template slot="footer"
                        ><i class="far fa-chart-pie-alt"></i>
                        {{
                            $t("reports.N_of_total_X", {
                                N: i18perc(stats.type_fixed, stats.count),
                                X: stats.count
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>

            <!-- Box 3 -->
            <div :class="statBoxClass">
                <inner-dash-card
                    :title="$t('reports.variable_contracts')"
                    icon="far fa-file-signature"
                >
                    <template slot="default">{{
                        stats.type_variable
                    }}</template>
                    <template slot="footer"
                        ><i class="far fa-chart-pie-alt"></i>
                        {{
                            $t("reports.N_of_total_X", {
                                N: i18perc(stats.type_variable, stats.count),
                                X: stats.count
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>

            <!-- Box 4 -->
            <div :class="statBoxClass">
                <inner-dash-card
                    :title="$t('reports.terminated')"
                    icon="far fa-siren-on"
                >
                    <template slot="default">{{
                        stats.has_termination
                    }}</template>
                    <template slot="footer"
                        ><i class="far fa-chart-pie-alt"></i>
                        {{
                            $t("reports.N_of_total_X", {
                                N: i18perc(stats.has_termination, stats.count),
                                X: stats.count
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>
            <!-- Box: owners wind -->
            <div :class="statBoxClass">
                <inner-dash-card
                    :title="$t('reports.contracts_wind')"
                    icon="far fa-wind-turbine"
                >
                    <template slot="default">{{ cstats.count_wind }}</template>
                    <template slot="footer"
                        ><i class="far fa-chart-pie-alt"></i>
                        {{
                            $t("reports.N_of_total_X", {
                                N: i18perc(
                                    cstats.count_wind,
                                    parseInt(cstats.count_wind) +
                                        parseInt(cstats.count_solar)
                                ),
                                X:
                                    parseInt(cstats.count_wind) +
                                    parseInt(cstats.count_solar)
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>
            <!-- Box: owners solar -->
            <div :class="statBoxClass">
                <inner-dash-card
                    :title="$t('reports.contracts_solar')"
                    icon="far fa-users"
                >
                    <template slot="default">{{ cstats.count_solar }}</template>
                    <template slot="footer"
                        ><i class="far fa-chart-pie-alt"></i>
                        {{
                            $t("reports.N_of_total_X", {
                                N: i18perc(
                                    cstats.count_solar,
                                    parseInt(cstats.count_wind) +
                                        parseInt(cstats.count_solar)
                                ),
                                X:
                                    parseInt(cstats.count_wind) +
                                    parseInt(cstats.count_solar)
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>
        </div>
        <!-- .row -->
        <div class="row">
            <div class="col-6">
                <h3>
                    {{
                        $t(
                            "reports.distribution_by_contract_type_and_asset_type"
                        )
                    }}
                </h3>
                <div style="display: block; height: 350px">
                    <apexchart
                        height="100%"
                        :options="{
                            chart: {
                                type: 'donut'
                            },
                            labels: [
                                $t('reports.contract_fixed_wind'),
                                $t('reports.contract_variable_wind'),
                                $t('reports.contract_fixed_solar'),
                                $t('reports.contract_variable_solar')
                            ],

                            responsive: [
                                {
                                    breakpoint: 480,
                                    options: {
                                        chart: {
                                            width: '100%'
                                        },
                                        legend: {
                                            position: 'bottom'
                                        }
                                    }
                                }
                            ]
                        }"
                        :series="[
                            parseFloat(stats.type_fixed_wind),
                            parseFloat(stats.type_variable_wind),
                            parseFloat(stats.type_fixed_solar),
                            parseFloat(stats.type_variable_solar)
                        ]"
                    ></apexchart>
                </div>
            </div>
            <div class="col-6">
                <h3>{{ $t("reports.distribution_by_payment_frequency") }}</h3>
                <div style="display: block; height: 350px">
                    <apexchart
                        height="100%"
                        :options="{
                            chart: {
                                type: 'donut'
                            },
                            labels: translatedCategories,

                            responsive: [
                                {
                                    breakpoint: 480,
                                    options: {
                                        chart: {
                                            width: '100%'
                                        },
                                        legend: {
                                            position: 'bottom'
                                        }
                                    }
                                }
                            ]
                        }"
                        :series="stats.freqs.series"
                    ></apexchart>
                </div>
            </div>
        </div>
    </div>
</template>

<style></style>
