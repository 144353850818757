<script>
export default {
    props: {
        data: {}
    },
    computed: {
        sumCountWind() {
            return (
                this.data[1]["wind_count"] +
                this.data[2]["wind_count"] +
                this.data[3]["wind_count"]
            );
        },
        sumCountSolar() {
            return (
                this.data[1]["solar_count"] +
                this.data[2]["solar_count"] +
                this.data[3]["solar_count"]
            );
        },
        sumRatingWind() {
            return (
                this.data[1]["wind_rating"] +
                this.data[2]["wind_rating"] +
                this.data[3]["wind_rating"]
            );
        },
        sumRatingSolar() {
            return (
                this.data[1]["solar_rating"] +
                this.data[2]["solar_rating"] +
                this.data[3]["solar_rating"]
            );
        },
        sumValueWind() {
            return (
                this.data[1]["wind_value"] +
                this.data[2]["wind_value"] +
                this.data[3]["wind_value"]
            );
        },
        sumValueSolar() {
            return (
                this.data[1]["solar_value"] +
                this.data[2]["solar_value"] +
                this.data[3]["solar_value"]
            );
        }
    }
};
</script>

<template>
    <ul class="list-group mt-4">
        <li class="list-group-item">
            <div class="row">
                <div class="col text-bold"></div>
                <div class="col text-right text-bold">
                    {{ $t("reports.segment_meg") }}
                </div>
                <div class="col text-right text-bold">
                    {{ $t("reports.segment_private") }}
                </div>
                <div class="col text-right text-bold">
                    {{ $t("reports.segment_institutional") }}
                </div>
               
                <div class="col text-right text-bold">
                    {{ $t("reports.segment_total") }}
                </div>
            </div>
        </li>
        <li class="list-group-item">
            <div class="row">
                <div class="col text-bold">
                    Antal
                </div>
                <div class="col text-right">
                    <div>
                        {{ $n(data[1]["wind_count"]) }}
                        <i class="fa fa-wind-turbine fa-fw text-muted ml-2"></i>
                    </div>
                    <div>
                        {{ $n(data[1]["solar_count"]) }}
                        <i class="fa fa-solar-panel fa-fw text-muted ml-2"></i>
                    </div>
                </div>
                <div class="col text-right">
                    <div>
                        {{ $n(data[2]["wind_count"]) }}
                        <i class="fa fa-wind-turbine fa-fw text-muted ml-2"></i>
                    </div>
                    <div>
                        {{ $n(data[2]["solar_count"]) }}
                        <i class="fa fa-solar-panel fa-fw text-muted ml-2"></i>
                    </div>
                </div>
                <div class="col text-right">
                    <div>
                        {{ $n(data[3]["wind_count"]) }}
                        <i class="fa fa-wind-turbine fa-fw text-muted ml-2"></i>
                    </div>
                    <div>
                        {{ $n(data[3]["solar_count"]) }}
                        <i class="fa fa-solar-panel fa-fw text-muted ml-2"></i>
                    </div>
                </div>
                <div class="col text-right">
                    <div>
                        {{ $n(sumCountWind) }}
                        <i class="fa fa-wind-turbine fa-fw text-muted ml-2"></i>
                    </div>
                    <div>
                        {{ $n(sumCountSolar) }}
                        <i class="fa fa-solar-panel fa-fw text-muted ml-2"></i>
                    </div>
                    <div class="totaler">
                        {{ $n(sumCountWind + sumCountSolar) }}
                        <i class="fa fa-bars fa-fw text-muted ml-2"></i>
                    </div>
                </div>
            </div>
        </li>
        <li class="list-group-item">
            <div class="row">
                <div class="col text-bold">
                    Effekt
                </div>
                <div class="col text-right">
                    <div>
                        {{ $n(data[1]["wind_rating"]) }} MW
                        <i class="fa fa-wind-turbine fa-fw text-muted ml-2"></i>
                    </div>
                    <div>
                        {{ $n(data[1]["solar_rating"]) }} MW
                        <i class="fa fa-solar-panel fa-fw text-muted ml-2"></i>
                    </div>
                </div>
                <div class="col text-right">
                    <div>
                        {{ $n(data[2]["wind_rating"]) }} MW
                        <i class="fa fa-wind-turbine fa-fw text-muted ml-2"></i>
                    </div>
                    <div>
                        {{ $n(data[2]["solar_rating"]) }} MW
                        <i class="fa fa-solar-panel fa-fw text-muted ml-2"></i>
                    </div>
                </div>
                <div class="col text-right">
                    <div>
                        {{ $n(data[3]["wind_rating"]) }} MW
                        <i class="fa fa-wind-turbine fa-fw text-muted ml-2"></i>
                    </div>
                    <div>
                        {{ $n(data[3]["solar_rating"]) }} MW
                        <i class="fa fa-solar-panel fa-fw text-muted ml-2"></i>
                    </div>
                </div>
                <div class="col text-right">
                    <div>
                        {{ $n(sumRatingWind, "decimal") }} MW
                        <i class="fa fa-wind-turbine fa-fw text-muted ml-2"></i>
                    </div>
                    <div>
                        {{ $n(sumRatingSolar, "decimal") }} MW
                        <i class="fa fa-solar-panel fa-fw text-muted ml-2"></i>
                    </div>
                    <div class="totaler">
                        {{ $n(sumRatingWind + sumRatingSolar, "decimal") }} MW
                        <i class="fa fa-bars fa-fw text-muted ml-2"></i>
                    </div>
                </div>
            </div>
        </li>
        <li class="list-group-item">
            <div class="row">
                <div class="col text-bold">
                    Værdi
                </div>
                <div class="col text-right">
                    <div>
                        {{ data[1]["wind_value"] | numanize }} DKK
                        <i class="fa fa-wind-turbine fa-fw text-muted ml-2"></i>
                    </div>
                    <div>
                        {{ data[1]["solar_value"] | numanize }} DKK
                        <i class="fa fa-solar-panel fa-fw text-muted ml-2"></i>
                    </div>
                </div>
                <div class="col text-right">
                    <div>
                        {{ data[2]["wind_value"] | numanize }} DKK
                        <i class="fa fa-wind-turbine fa-fw text-muted ml-2"></i>
                    </div>
                    <div>
                        {{ data[2]["solar_value"] | numanize }} DKK
                        <i class="fa fa-solar-panel fa-fw text-muted ml-2"></i>
                    </div>
                </div>
                <div class="col text-right">
                    <div>
                        {{ data[3]["wind_value"] | numanize }} DKK
                        <i class="fa fa-wind-turbine fa-fw text-muted ml-2"></i>
                    </div>
                    <div>
                        {{ data[3]["solar_value"] | numanize }} DKK
                        <i class="fa fa-solar-panel fa-fw text-muted ml-2"></i>
                    </div>
                </div>
                <div class="col text-right">
                    <div>
                        {{ sumValueWind | numanize }} DKK
                        <i class="fa fa-wind-turbine fa-fw text-muted ml-2"></i>
                    </div>
                    <div>
                        {{ sumValueSolar | numanize }} DKK
                        <i class="fa fa-solar-panel fa-fw text-muted ml-2"></i>
                    </div>
                    <div class="totaler">
                        {{ (sumValueWind + sumValueSolar) | numanize }} DKK
                        <i class="fa fa-bars fa-fw text-muted ml-2"></i>
                    </div>
                </div>
            </div>
        </li>
        <pre v-if="false">{{ data }}</pre>
    </ul>
</template>

<style lang="scss">
.totaler {
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px solid $gray-400;
}
</style>
