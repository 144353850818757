var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('card',{attrs:{"body-classes":"px-0"}},[_c('h3',{staticClass:"px-4 mb-4"},[_vm._v(" "+_vm._s(_vm.$t("reports.active_distribution_per_segment_in_COUNTRY", { COUNTRY: "DK" }))+" ")]),_c('div',{staticClass:"px-4"},[_c('v-top-segment-table',{attrs:{"data":_vm.cs.asset_segments.segmented}})],1),_c('div',{staticClass:"d-flex align-items-start justify-content-between px-4 mt-5"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t( "reports.active_distribution_per_segment_in_COUNTRY", { COUNTRY: "DE" } ))+" "),_c('div',{staticClass:"small text-muted"},[(_vm.loading === false)?[_c('ul',{staticClass:"list-inline"},[_c('li',[_vm._v("MEG: "+_vm._s(_vm.dates["seg-de-meg"]))]),_c('li',[_vm._v("INST: "+_vm._s(_vm.dates["seg-de-ins"]))]),_c('li',[_vm._v("PRIV: "+_vm._s(_vm.dates["seg-de-prv"]))])])]:_vm._e()],2)]),_c('div',[_c('base-button',{attrs:{"type":"link","outline":true,"size":"sm"},on:{"click":function($event){return _vm.$router.push({
                            name: 'ContractsReportsData',
                            params: { src_group: 'seg-de-meg' }
                        })}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("reports.add_SLUGNAME", { SLUGNAME: "MEG" })))]),_c('base-button',{attrs:{"type":"link","outline":true,"size":"sm"},on:{"click":function($event){return _vm.$router.push({
                            name: 'ContractsReportsData',
                            params: { src_group: 'seg-de-prv' }
                        })}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("reports.add_SLUGNAME", { SLUGNAME: "Private" })))]),_c('base-button',{attrs:{"type":"link","outline":true,"size":"sm"},on:{"click":function($event){return _vm.$router.push({
                            name: 'ContractsReportsData',
                            params: { src_group: 'seg-de-ins' }
                        })}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("reports.add_SLUGNAME", { SLUGNAME: "Institutional" })))])],1)]),(_vm.loading === false)?_c('div',{staticClass:"px-4"},[(
                    Object.entries(_vm.data['seg-de-meg']).length > 0 &&
                        Object.entries(_vm.data['seg-de-ins']).length > 0 &&
                        Object.entries(_vm.data['seg-de-prv']).length > 0
                )?[_c('v-top-segment-table',{attrs:{"data":{
                        1: {
                            wind_count: _vm.data['seg-de-meg'].count_wind,
                            solar_count: _vm.data['seg-de-meg'].count_solar,
                            wind_rating: _vm.data['seg-de-meg'].power_wind,
                            solar_rating: _vm.data['seg-de-meg'].power_solar,
                            wind_value: _vm.data['seg-de-meg'].value_wind,
                            solar_value: _vm.data['seg-de-meg'].value_solar
                        },
                        3: {
                            wind_count: _vm.data['seg-de-ins'].count_wind,
                            solar_count: _vm.data['seg-de-ins'].count_solar,
                            wind_rating: _vm.data['seg-de-ins'].power_wind,
                            solar_rating: _vm.data['seg-de-ins'].power_solar,
                            wind_value: _vm.data['seg-de-ins'].value_wind,
                            solar_value: _vm.data['seg-de-ins'].value_solar
                        },
                        2: {
                            wind_count: _vm.data['seg-de-prv'].count_wind,
                            solar_count: _vm.data['seg-de-prv'].count_solar,
                            wind_rating: _vm.data['seg-de-prv'].power_wind,
                            solar_rating: _vm.data['seg-de-prv'].power_solar,
                            wind_value: _vm.data['seg-de-prv'].value_wind,
                            solar_value: _vm.data['seg-de-prv'].value_solar
                        }
                    }}})]:_c('div',{staticClass:"alert alert-warning mt-4"},[_vm._v(" "+_vm._s(_vm.$t("reports.no_data_add_first"))+" ")])],2):_vm._e(),_c('div',{staticClass:"d-flex align-items-start justify-content-between px-4 mt-5"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t( "reports.active_distribution_per_segment_in_COUNTRY", { COUNTRY: "MEG" } ))+" "),_c('div',{staticClass:"small text-muted"},[(_vm.loading === false)?[_c('ul',{staticClass:"list-inline"},[_c('li',[_vm._v("MEG: "+_vm._s(_vm.dates["seg-meg-meg"]))]),_c('li',[_vm._v("INST: "+_vm._s(_vm.dates["seg-meg-ins"]))]),_c('li',[_vm._v("PRIV: "+_vm._s(_vm.dates["seg-meg-prv"]))])])]:_vm._e()],2)]),_c('div',[_c('base-button',{attrs:{"type":"link","outline":true,"size":"sm"},on:{"click":function($event){return _vm.$router.push({
                            name: 'ContractsReportsData',
                            params: { src_group: 'seg-meg-meg' }
                        })}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("reports.add_SLUGNAME", { SLUGNAME: "MEG" })))]),_c('base-button',{attrs:{"type":"link","outline":true,"size":"sm"},on:{"click":function($event){return _vm.$router.push({
                            name: 'ContractsReportsData',
                            params: { src_group: 'seg-meg-prv' }
                        })}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("reports.add_SLUGNAME", { SLUGNAME: "Private" })))]),_c('base-button',{attrs:{"type":"link","outline":true,"size":"sm"},on:{"click":function($event){return _vm.$router.push({
                            name: 'ContractsReportsData',
                            params: { src_group: 'seg-meg-ins' }
                        })}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("reports.add_SLUGNAME", { SLUGNAME: "Institutional" })))])],1)]),(_vm.loading === false)?_c('div',{staticClass:"px-4"},[(
                    Object.entries(_vm.data['seg-meg-meg']).length > 0 &&
                        Object.entries(_vm.data['seg-meg-ins']).length > 0 &&
                        Object.entries(_vm.data['seg-meg-prv']).length > 0
                )?[_c('v-top-segment-table',{attrs:{"data":{
                        1: {
                            wind_count: _vm.data['seg-meg-meg'].count_wind,
                            solar_count: _vm.data['seg-meg-meg'].count_solar,
                            wind_rating: _vm.data['seg-meg-meg'].power_wind,
                            solar_rating: _vm.data['seg-meg-meg'].power_solar,
                            wind_value: _vm.data['seg-meg-meg'].value_wind,
                            solar_value: _vm.data['seg-meg-meg'].value_solar
                        },
                        2: {
                            wind_count: _vm.data['seg-meg-prv'].count_wind,
                            solar_count: _vm.data['seg-meg-prv'].count_solar,
                            wind_rating: _vm.data['seg-meg-prv'].power_wind,
                            solar_rating: _vm.data['seg-meg-prv'].power_solar,
                            wind_value: _vm.data['seg-meg-prv'].value_wind,
                            solar_value: _vm.data['seg-meg-prv'].value_solar
                        },
                        3: {
                            wind_count: _vm.data['seg-meg-ins'].count_wind,
                            solar_count: _vm.data['seg-meg-ins'].count_solar,
                            wind_rating: _vm.data['seg-meg-ins'].power_wind,
                            solar_rating: _vm.data['seg-meg-ins'].power_solar,
                            wind_value: _vm.data['seg-meg-ins'].value_wind,
                            solar_value: _vm.data['seg-meg-ins'].value_solar
                        }
                    }}})]:_c('div',{staticClass:"alert alert-warning mt-4"},[_vm._v(" "+_vm._s(_vm.$t("reports.no_data_add_first"))+" ")])],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }