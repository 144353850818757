var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('card',{attrs:{"body-classes":"px-0"}},[_c('h3',{staticClass:"px-4 mb-4"},[_vm._v(" "+_vm._s(_vm.$t("reports.active_distribution_in_COUNTRY", { COUNTRY: "DK" }))+" ")]),_c('div',{staticClass:"px-4"},[_c('v-top-table',{attrs:{"data":{
                    country: 'DK',
                    wind: {
                        count: _vm.cs.assets_stats.wind_count,
                        power: _vm.cs.assets_stats.wind_rating,
                        value: _vm.cs.assets_stats.wind_value
                    },
                    solar: {
                        count: _vm.cs.assets_stats.solar_count,
                        power: _vm.cs.assets_stats.solar_rating,
                        value: _vm.cs.assets_stats.solar_value
                    }
                }}})],1),_c('div',{staticClass:"d-flex align-items-start justify-content-between px-4 mt-5"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("reports.active_distribution_in_COUNTRY", { COUNTRY: "DE" }))+" "),(_vm.hasC_DE)?_c('div',{staticClass:"small text-muted"},[_vm._v(" "+_vm._s(_vm.$t("reports.data_from"))+": "+_vm._s(_vm.$d(new Date(_vm.cStatsDE.entry_date), "short"))+" ")]):_vm._e()]),_c('base-button',{attrs:{"type":"link","outline":true,"size":"sm"},on:{"click":function($event){return _vm.$router.push({
                        name: 'ContractsReportsData',
                        params: { src_group: 'faa-de' }
                    })}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("manage_data")))])],1),_c('div',{staticClass:"px-4"},[(_vm.hasC_DE)?_c('v-top-table',{attrs:{"data":{
                    country: 'DE',
                    wind: {
                        count: _vm.cStatsDE.count_wind,
                        power: _vm.cStatsDE.power_wind,
                        value: _vm.cStatsDE.value_wind
                    },
                    solar: {
                        count: _vm.cStatsDE.count_solar,
                        power: _vm.cStatsDE.power_solar,
                        value: _vm.cStatsDE.value_solar
                    }
                }}}):_c('div',{staticClass:"alert alert-warning mt-4"},[_vm._v(" "+_vm._s(_vm.$t("reports.no_data_add_first"))+" ")])],1),_c('div',{staticClass:"d-flex align-items-start justify-content-between px-4 mt-5"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("reports.active_distribution_in_COUNTRY", { COUNTRY: "MEG" }))+" "),(_vm.hasC_MEG)?_c('div',{staticClass:"small text-muted"},[_vm._v(" "+_vm._s(_vm.$t("reports.data_from"))+": "+_vm._s(_vm.$d(new Date(_vm.cStatsMEG.entry_date), "short"))+" ")]):_vm._e()]),_c('base-button',{attrs:{"type":"link","outline":true,"size":"sm"},on:{"click":function($event){return _vm.$router.push({
                        name: 'ContractsReportsData',
                        params: { src_group: 'faa-meg' }
                    })}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("manage_data")))])],1),_c('div',{staticClass:"px-4"},[(_vm.hasC_MEG)?_c('v-top-table',{attrs:{"data":{
                    country: 'DE',
                    wind: {
                        count: _vm.cStatsMEG.count_wind,
                        power: _vm.cStatsMEG.power_wind,
                        value: _vm.cStatsMEG.value_wind
                    },
                    solar: {
                        count: _vm.cStatsMEG.count_solar,
                        power: _vm.cStatsMEG.power_solar,
                        value: _vm.cStatsMEG.value_solar
                    }
                }}}):_c('div',{staticClass:"alert alert-warning mt-4"},[_vm._v(" "+_vm._s(_vm.$t("reports.no_data_add_first"))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }