<script>
export default {
    name: "InnerDashCard",
    props: {
        title: {
            default: "",
        },
        icon: {
            default: "far fa-star",
        },
    },
};
</script>

<template>
    <div class="card inner-dash-card">
        <!-- Card body -->
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                        {{ title }}
                    </h5>                    
                </div>
                <div class="col-auto inner-dash-card__icon">
                    <i class="fa-fw" :class="icon"></i>
                </div>
            </div>
            <div class="row inner-dash-card__slots">
                <div class="col inner-dash-card__left"><slot name="default"></slot></div>
                <div class="col inner-dash-card__right"><slot name="action"></slot></div>
            </div>            
            <div class="row inner-dash-card__footer" v-if="$slots.footer">
                <div class="col inner-dash-card__footer--content">
                    <slot name="footer" />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.inner-dash-card {

    box-shadow: none;
    border: 1px solid $gray-400; 
    border-radius: 6px;

    &__footer {
        margin-top: 10px;
        &--content {
            color: $gray-600;
            font-size: 14px;
        }
    }

    &__slots {
        display: flex;
        align-items: baseline;
    }

    &__left {
        //border: 1px dotted red;
        font-size: 20px; 
        font-weight: 600;
    }

    &__right {
        //border: 1px dotted green;
        text-align: right;
        font-size: 13px;
    }

    .card-title {
        font-weight: bold;
    }    

    & .card-body {
        padding: 18px;
    }

    &__icon {        
        font-size: 20px;
    }
}
</style>