<script>
import CurrencyHumanizer from "@/util/MillionHumanizer";
import NumberHumanizer from "@/util/NumberHumanizer";
import QMath from "@/libraries/QMath";

export default {
    name: "VContractDetails",
    props: {
        ac: {}
    },
    computed: {
        countryLabels() {
            return Object.keys(this.ac);
        },
        countryLabelsTotals() {
            let arr = [];
            this.countryLabels.forEach(country => {
                arr.push(country + ": " + this.$n(this.ac[country].total));
            });

            return arr;
        },
        countryTotals() {
            let arr = [];
            this.countryLabels.forEach(country => {
                arr.push(this.ac[country].total);
            });

            return arr;
        }
    },
    methods: {
        ch: CurrencyHumanizer,
        nh: NumberHumanizer,
        perc: QMath.perc,
        i18perc: QMath.i18perc
    }
};
</script>

<template>
    <div>
        <pre v-if="false">{{ ac }}</pre>
        <!-- .row -->
        <div class="row">
            <div class="col-4" v-if="true">
                <h3>{{ $t("reports.asset_by_country") }}</h3>
                <div style="display: block; height: 350px">
                    <apexchart
                        height="100%"
                        :options="{
                            chart: {
                                type: 'donut'
                            },
                            labels: countryLabelsTotals,
                            yaxis: {
                                labels: {
                                    formatter: function(value) {
                                        return $n(value);
                                    }
                                }
                            },
                            responsive: [
                                {
                                    breakpoint: 480,
                                    options: {
                                        chart: {
                                            width: '100%'
                                        },
                                        legend: {
                                            position: 'bottom'
                                        }
                                    }
                                }
                            ]
                        }"
                        :series="countryTotals"
                    ></apexchart>
                </div>
            </div>
            <!-- .col-6 -->
            <div class="col-8" v-if="true">
                <h3>{{ $t("reports.asset_by_country_details") }}</h3>
                <v-table class="table table-sm">
                    <v-thead>
                        <v-tr>
                            <v-th class="text-center">{{
                                $t("reports.country")
                            }}</v-th>
                            <v-th class="text-right">{{
                                $t("reports.total")
                            }}</v-th>
                            <v-th class="text-right">{{
                                $t("reports.count")
                            }}</v-th>
                            <v-th class="text-right">{{
                                $t("reports.rating")
                            }}</v-th>
                            <v-th class="text-right">{{
                                $t("reports.value")
                            }}</v-th>
                        </v-tr>
                    </v-thead>
                    <v-tbody>
                        <v-tr v-for="(d, idx) in ac" :key="idx">
                            <v-td class="text-center">{{ idx }}</v-td>
                            <v-td class="text-right">{{ d.total }}</v-td>
                            <v-td class="text-right"
                                >{{ d.wind_count }}
                                <i
                                    class="text-muted fa fa-wind-turbine fa-fw ml-1"
                                ></i
                                ><br />
                                {{ d.solar_count }}
                                <i
                                    class="text-muted fa fa-solar-panel fa-fw ml-1"
                                ></i
                            ></v-td>
                            <v-td class="text-right"
                                >{{ $n(d.wind_rating, "decimal") }} MW
                                <i
                                    class="text-muted fa fa-wind-turbine fa-fw ml-1"
                                ></i
                                ><br />
                                {{ $n(d.solar_rating, "decimal") }} MW
                                <i
                                    class="text-muted fa fa-solar-panel fa-fw ml-1"
                                ></i
                            ></v-td>
                            <v-td class="text-right"
                                >{{ d.wind_value | numanize }} DKK
                                <i
                                    class="text-muted fa fa-wind-turbine fa-fw ml-1"
                                ></i
                                ><br />
                                {{ d.solar_value | numanize }} DKK
                                <i
                                    class="text-muted fa fa-solar-panel fa-fw ml-1"
                                ></i
                            ></v-td>
                        </v-tr>
                    </v-tbody>
                </v-table>
            </div>
            <!-- .col-6 -->
        </div>
        <!-- .row -->
        <div class="row mt-5" v-if="false">
            <div class="col-6">
                <h3>
                    {{ $t("reports.yearly_contract_income_in_home_currency") }}
                </h3>
                <ul class="list-group mt-4">
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col text-bold">
                                {{ $t("report.currency") }}
                            </div>
                            <div class="col text-right text-bold">
                                {{ $t("report.amount_in_currency") }}
                            </div>
                            <div class="col text-right text-bold">
                                {{
                                    $t("report.amount_converted_to_X", {
                                        X: currency
                                    })
                                }}
                            </div>
                        </div>
                    </li>
                    <li
                        v-for="(amount, currency) in stats.currencies"
                        :key="currency"
                        class="list-group-item"
                    >
                        <div class="row">
                            <div class="col">
                                <i class="far fa-coins"></i> {{ currency }}
                            </div>
                            <div class="col text-right">
                                {{
                                    $n(amount, {
                                        style: "currency",
                                        currency: currency,
                                        currencyDisplay: "symbol"
                                    })
                                }}
                            </div>
                            <div class="col text-right">
                                {{
                                    $n(stats.currencies_conv[currency], {
                                        style: "currency",
                                        currency: stats.currency,
                                        currencyDisplay: "code"
                                    })
                                }}
                            </div>
                        </div>
                    </li>
                </ul>

                <h4 class="mt-4">
                    {{ $t("reports.distribution_by_currency") }}
                </h4>
                <div class="mt-2" style="display: block; height: 240px">
                    <apexchart
                        height="100%"
                        :options="{
                            chart: {
                                type: 'donut'
                            },
                            labels: Object.keys(stats.currencies_conv),
                            yaxis: {
                                labels: {
                                    formatter: function(value) {
                                        return ch(value, stats.currency);
                                    }
                                }
                            },
                            responsive: [
                                {
                                    breakpoint: 480,
                                    options: {
                                        chart: {
                                            width: '100%'
                                        },
                                        legend: {
                                            position: 'bottom'
                                        }
                                    }
                                }
                            ]
                        }"
                        :series="Object.values(stats.currencies_conv)"
                    ></apexchart>
                </div>
            </div>
            <div class="col-6">
                <h3>{{ $t("reports.yearly_invoice_plan") }}</h3>
                <div class="table-responsive">
                    <v-table class="table table-sm table-striped">
                        <v-thead>
                            <v-tr>
                                <v-th>{{ $t("reports.invoice_date") }}</v-th>
                                <v-th class="text-right">{{
                                    $t("reports.amount")
                                }}</v-th>
                            </v-tr>
                        </v-thead>
                        <v-tbody>
                            <v-tr
                                v-for="(amount, date) in stats.days"
                                :key="date"
                            >
                                <v-td>{{ $d(new Date(date), "short") }}</v-td>
                                <v-td class="text-right">{{
                                    $n(amount, {
                                        style: "currency",
                                        currency: stats.currency,
                                        currencyDisplay: "code"
                                    })
                                }}</v-td>
                            </v-tr>
                        </v-tbody>
                    </v-table>
                </div>
            </div>
        </div>
        <!-- .row -->
    </div>
</template>

<style></style>
