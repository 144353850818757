import i18n from '@/i18n';

export default { 
    perc(current, reference) {
        if (reference != 0) {
            return Number.parseFloat(
                (current / reference) * 100
            ).toFixed(2);
        } else {
            return 0;
        }
    },
    i18perc(current, reference) {
        let n = this.perc(current, reference);

        let formatted = i18n.n(n, {
            style: "decimal",
            minimumFractionDigits: 1,
            maximumFractionDigits: 2
        });

        return formatted;
    }
};