<script>
export default {
    props: {
        data: {}
    },
    computed: {
        sumCount() {
            return (
                parseInt(this.data.wind.count) + parseInt(this.data.solar.count)
            );
        },
        sumPower() {
            return (
                parseFloat(this.data.wind.power) +
                parseFloat(this.data.solar.power)
            );
        },
        sumValue() {
            return (
                parseFloat(this.data.wind.value) +
                parseFloat(this.data.solar.value)
            );
        }
    }
};
</script>

<template>
    <ul class="list-group mt-4">
        <li class="list-group-item">
            <div class="row">
                <div class="col text-bold"></div>
                <div class="col text-right text-bold">
                    <i class="fa fa-wind-turbine"></i>
                </div>
                <div class="col text-right text-bold">
                    <i class="fa fa-solar-panel"></i>
                </div>
                <div class="col text-right text-bold">
                    {{ $t("report.total") }}
                </div>
            </div>
        </li>
        <li class="list-group-item">
            <div class="row">
                <div class="col text-bold">
                    {{ $t("report.par_qty") }}
                </div>
                <div class="col text-right">
                    {{ data.wind.count }}
                </div>
                <div class="col text-right ">
                    {{ data.solar.count }}
                </div>
                <div class="col text-right ">{{ sumCount }}</div>
            </div>
        </li>
        <li class="list-group-item">
            <div class="row">
                <div class="col text-bold">
                    {{ $t("report.par_power") }}
                </div>
                <div class="col text-right">{{ $n(data.wind.power) }} MW</div>
                <div class="col text-right">{{ $n(data.solar.power) }} MW</div>
                <div class="col text-right">{{ $n(sumPower) }} MW</div>
            </div>
        </li>
        <li class="list-group-item">
            <div class="row">
                <div class="col text-bold">
                    {{ $t("report.par_value") }}
                </div>
                <div class="col text-right ">
                    {{ data.wind.value | numanize }} DKK
                </div>
                <div class="col text-right ">
                    {{ data.solar.value | numanize }} DKK
                </div>
                <div class="col text-right">{{ sumValue | numanize }} DKK</div>
            </div>
        </li>
    </ul>
</template>
