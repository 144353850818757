<script>
import ContractStatService from "@/services/ContractStatService"; 
import Cloner from "@/libraries/Cloner";

export default {
    name: "VContractStatForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            }
        },
        statGroup: {}
    },
    data() {
        return {
            editing: false,
            loading: false,
            errors: null,
            entity: {
                id: null,
                stat_group: null,
                entry_date: null,
                count_wind: null,
                power_wind: null,
                value_wind: null,
                count_solar: null,
                power_solar: null,
                value_solar: null
            }
        };
    },
    created() {
        if (!this.context.empty) {
            this.editing = true;
            this.entity = Cloner.pickClone(this.context, [
                "id",
                "stat_group",
                "entry_date",
                "count_wind",
                "power_wind",
                "value_wind",
                "count_solar",
                "power_solar",
                "value_solar"
            ]);
        } else {
            this.entity.stat_group = this.statGroup;
        }
    },
    methods: {
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await ContractStatService.PUT(this.entity);
                } else {
                    console.log("FORM POST", this.entity);
                    const r = await ContractStatService.POST(this.entity);
                }

                this.$emit("close-refresh");
            } catch (e) {
                console.log("sf e", e);
                this.errors = e.response.data.errors;
            }
        }
    }
};
</script>

<template>
    <modal size="lg" :show="true" @close="$emit('close')" class="budget-modal">
        <template slot="header">
            <template v-if="editing">
                {{
                    $t("contract_stats.edit_entry_for_GROUP", {
                        GROUP: statGroup
                    })
                }}
            </template>
            <template v-else>
                {{
                    $t("contract_stats.create_entry_for_GROUP", {
                        GROUP: statGroup
                    })
                }}
            </template>
        </template>

        <template slot="default">
            <FormulateForm v-model="entity" @submit="saveForm" :errors="errors">
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <FormulateInput
                            name="entry_date"
                            type="date"
                            :placeholder="$t('contract_stats.date_placeholder')"
                            :label="$t('contract_stats.date')"
                            :validation-name="$t('contract_stats.date')"
                            validation="required"
                        />
                    </div>
                </div>
                <div class="line-separator"></div>

                <div class="mt-3 mb-3">
                    <i class="fa fa-wind-turbine fa-2x"></i>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <FormulateInput
                            name="count_wind"
                            type="grouptext"
                            append="stk."
                            :label="$t('contract_stats.count_assets')"
                            :validation-name="$t('contract_stats.count_assets')"
                            validation="required|number"
                        />
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <FormulateInput
                            name="power_wind"
                            type="groupdecimal"
                            append="MW"
                            :label="$t('contract_stats.power')"
                            :validation-name="$t('contract_stats.power')"
                            validation="required"
                        />
                    </div>
                    <div class="col-lg-4 col-xs-12">
                        <FormulateInput
                            name="value_wind"
                            type="currency"
                            currency="DKK"
                            :label="$t('contract_stats.value')"
                            :validation-name="$t('contract_stats.value')"
                            validation="required"
                        />
                    </div>
                </div>

                <div class="line-separator"></div>

                <div class="mt-3 mb-3">
                    <i class="fa fa-solar-panel fa-2x"></i>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-xs-12">
                        <FormulateInput
                            name="count_solar"
                            type="grouptext"
                            append="stk."
                            :label="$t('contract_stats.count_assets')"
                            :validation-name="$t('contract_stats.count_assets')"
                            validation="required|number"
                        />
                    </div>

                    <div class="col-lg-4 col-xs-12">
                        <FormulateInput
                            name="power_solar"
                            type="groupdecimal"
                            append="MW"
                            :label="$t('contract_stats.power')"
                            :validation-name="$t('contract_stats.power')"
                            validation="required"
                        />
                    </div>
                    <div class="col-lg-4 col-xs-12">
                        <FormulateInput
                            name="value_solar"
                            type="currency"
                            currency="DKK"
                            :label="$t('contract_stats.value')"
                            :validation-name="$t('contract_stats.value')"
                            validation="required"
                        />
                    </div>
                </div>

                <div class="line-separator"></div>

                <div class="text-right mt-4">
                    <base-button
                        type="secondary"
                        :outline="true"
                        @click="$emit('close')"
                        >{{ $t("cancel") }}</base-button
                    >
                    <base-button
                        type="primary"
                        :loading="loading"
                        native-type="submit"
                        >{{ $t("save") }}</base-button
                    >
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>
