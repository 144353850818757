<script>
export default {
    name: "InnerDashCard",
    props: {
        title: {
            default: "",
        },
        value: {

        },
        type: {
            type: String,
            default: "",
            description: "success warning danger etc."
        },
        footer: {
            type: String,
            default: ""
        }
    },
};
</script>

<template>
    <div class="card hinner-dash-card">
        <!-- Card body -->
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                        {{ title }}
                    </h5>                    
                </div>                
            </div>
            <div class="row hinner-dash-card__slots">
                <div class="col hinner-dash-card__left" 
                :class="type.length > 0 ? `text-${type}`: ''">{{value}}</div>
            </div>            
            <div class="row hinner-dash-card__footer" v-if="footer.length > 0">
                <div class="col hinner-dash-card__footer_content">{{footer}}</div>
            </div>

        </div>
    </div>
</template>

<style lang="scss">
.hinner-dash-card {

    box-shadow: none;
    border: 1px solid $gray-400; 
    border-radius: 6px;

    

    &__slots {
        display: flex;
        align-items: baseline;
    }

    &__left {
        //border: 1px dotted red;
        font-size: 20px; 
        font-weight: 600;
    }
    

    .card-title {
        font-weight: bold;
    }    

    & .card-body {
        padding: 18px;
    }
   

    &__footer {
        &_content {
            font-size: 13px;
            color: $gray-600;
        }
    }


}
</style>