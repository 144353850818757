<script>
/**
 * Assets by countries:
 * - top from the system
 * - x2 from stat service
 */
import ContractStatService from "@/services/ContractStatService";
import VTopTable from "@/views/Reports/Contracts/components/VTopTable";

export default {
    props: {
        cs: {}
    },
    components: { VTopTable },
    data() {
        return {
            // regular
            hasC_DE: false,
            hasC_MEG: false,
            cStatsDE: [],
            cStatsMEG: []
        };
    },
    created() {
        this.fetchContractStats();
    },
    methods: {
        async fetchContractStats() {
            // DE
            try {
                const { data } = await ContractStatService.GET_latest("faa-de");
                this.cStatsDE = data.data;
                this.hasC_DE = true;
            } catch (e) {}

            // MEG
            try {
                const { data } = await ContractStatService.GET_latest(
                    "faa-meg"
                );
                this.cStatsMEG = data.data;
                this.hasC_MEG = true;
            } catch (e) {}
        }
    }
};
</script>

<template>
    <div class="col-12">
        <card body-classes="px-0">
            <!-- .DK -->
            <h3 class="px-4 mb-4">
                {{
                    $t("reports.active_distribution_in_COUNTRY", {
                        COUNTRY: "DK"
                    })
                }}
            </h3>
            <div class="px-4">
                <v-top-table
                    :data="{
                        country: 'DK',
                        wind: {
                            count: cs.assets_stats.wind_count,
                            power: cs.assets_stats.wind_rating,
                            value: cs.assets_stats.wind_value
                        },
                        solar: {
                            count: cs.assets_stats.solar_count,
                            power: cs.assets_stats.solar_rating,
                            value: cs.assets_stats.solar_value
                        }
                    }"
                />
            </div>

            <!-- .DE -->
            <div
                class="d-flex align-items-start justify-content-between px-4 mt-5"
            >
                <h3 class="mb-0">
                    {{
                        $t("reports.active_distribution_in_COUNTRY", {
                            COUNTRY: "DE"
                        })
                    }}
                    <div class="small text-muted" v-if="hasC_DE">
                        {{ $t("reports.data_from") }}:
                        {{ $d(new Date(cStatsDE.entry_date), "short") }}
                    </div>
                </h3>
                <base-button
                    type="link"
                    :outline="true"
                    size="sm"
                    @click="
                        $router.push({
                            name: 'ContractsReportsData',
                            params: { src_group: 'faa-de' }
                        })
                    "
                    ><i class="fa fa-plus"></i>
                    {{ $t("manage_data") }}</base-button
                >
            </div>
            <div class="px-4">
                <v-top-table
                    v-if="hasC_DE"
                    :data="{
                        country: 'DE',
                        wind: {
                            count: cStatsDE.count_wind,
                            power: cStatsDE.power_wind,
                            value: cStatsDE.value_wind
                        },
                        solar: {
                            count: cStatsDE.count_solar,
                            power: cStatsDE.power_solar,
                            value: cStatsDE.value_solar
                        }
                    }"
                />
                <div v-else class="alert alert-warning mt-4">
                    {{ $t("reports.no_data_add_first") }}
                </div>
            </div>

            <!-- .MEG -->
            <div
                class="d-flex align-items-start justify-content-between px-4 mt-5"
            >
                <h3 class="mb-0">
                    {{
                        $t("reports.active_distribution_in_COUNTRY", {
                            COUNTRY: "MEG"
                        })
                    }}
                    <div class="small text-muted" v-if="hasC_MEG">
                        {{ $t("reports.data_from") }}:
                        {{ $d(new Date(cStatsMEG.entry_date), "short") }}
                    </div>
                </h3>
                <base-button
                    type="link"
                    :outline="true"
                    size="sm"
                    @click="
                        $router.push({
                            name: 'ContractsReportsData',
                            params: { src_group: 'faa-meg' }
                        })
                    "
                    ><i class="fa fa-plus"></i>
                    {{ $t("manage_data") }}</base-button
                >
            </div>
            <div class="px-4">
                <v-top-table
                    v-if="hasC_MEG"
                    :data="{
                        country: 'DE',
                        wind: {
                            count: cStatsMEG.count_wind,
                            power: cStatsMEG.power_wind,
                            value: cStatsMEG.value_wind
                        },
                        solar: {
                            count: cStatsMEG.count_solar,
                            power: cStatsMEG.power_solar,
                            value: cStatsMEG.value_solar
                        }
                    }"
                />
                <div v-else class="alert alert-warning mt-4">
                    {{ $t("reports.no_data_add_first") }}
                </div>
            </div>
        </card>
    </div>
</template>
