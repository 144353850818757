<script>
import InnerDashCard from "@/components/Cards/InnerDashCard";
import CurrencyHumanizer from "@/util/MillionHumanizer";
import NumberHumanizer from "@/util/NumberHumanizer";
import QMath from "@/libraries/QMath";

export default {
    name: "VGlobalStats",
    props: {
        stats: {
            description: "Assets stats",
        },
        cstats: {
            description: "Contract stats",
        },
    },
    components: { InnerDashCard },
    methods: {
        ch: CurrencyHumanizer,
        nh: NumberHumanizer,
        perc: QMath.perc,
        i18perc: QMath.i18perc,
    },
};
</script>

<template>
    <div>
        <pre v-if="false">{{ stats }}{{ cstats }}</pre>
        <div class="row">
            <!-- Box: number of wind turbines -->
            <div class="col-12 col-md-6 col-lg-3">
                <inner-dash-card
                    :title="$t('reports.windmills')"
                    icon="far fa-wind-turbine"
                >
                    <template slot="default">{{ stats.wind_count }}</template>
                    <template slot="footer"
                        ><i class="far fa-chart-pie-alt"></i>
                        {{
                            $t("reports.N_of_total_X", {
                                N: i18perc(
                                    stats.wind_count,
                                    parseInt(stats.wind_count) +
                                        parseInt(stats.solar_count)
                                ),
                                X:
                                    parseInt(stats.wind_count) +
                                    parseInt(stats.solar_count),
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>
            <!-- Box: number of solar installations -->
            <div class="col-12 col-md-6 col-lg-3">
                <inner-dash-card
                    :title="$t('reports.solar_systems')"
                    icon="far fa-solar-panel"
                >
                    <template slot="default">{{ stats.solar_count }}</template>
                    <template slot="footer"
                        ><i class="far fa-chart-pie-alt"></i>
                        {{
                            $t("reports.N_of_total_X", {
                                N: i18perc(
                                    stats.solar_count,
                                    parseInt(stats.wind_count) +
                                        parseInt(stats.solar_count)
                                ),
                                X:
                                    parseInt(stats.wind_count) +
                                    parseInt(stats.solar_count),
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>
            <!-- Box: owners wind -->
            <div class="col-12 col-md-6 col-lg-3">
                <inner-dash-card
                    :title="$t('reports.contracts_wind')"
                    icon="far fa-wind-turbine"
                >
                    <template slot="default">{{ cstats.count_wind }}</template>
                    <template slot="footer"
                        ><i class="far fa-chart-pie-alt"></i>
                        {{
                            $t("reports.N_of_total_X", {
                                N: i18perc(
                                    cstats.count_wind,
                                    parseInt(cstats.count_wind) +
                                        parseInt(cstats.count_solar)
                                ),
                                X:
                                    parseInt(cstats.count_wind) +
                                    parseInt(cstats.count_solar),
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>
            <!-- Box: owners solar -->
            <div class="col-12 col-md-6 col-lg-3">
                <inner-dash-card
                    :title="$t('reports.contracts_solar')"
                    icon="far fa-users"
                >
                    <template slot="default">{{ cstats.count_solar }}</template>
                    <template slot="footer"
                        ><i class="far fa-chart-pie-alt"></i>
                        {{
                            $t("reports.N_of_total_X", {
                                N: i18perc(
                                    cstats.count_solar,
                                    parseInt(cstats.count_wind) +
                                        parseInt(cstats.count_solar)
                                ),
                                X:
                                    parseInt(cstats.count_wind) +
                                    parseInt(cstats.count_solar),
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>
        </div>
    </div>
</template>

<style>
</style>