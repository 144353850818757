<script>
import InnerDashCard from "@/components/Cards/InnerDashCard";
import CurrencyHumanizer from "@/util/MillionHumanizer";
import NumberHumanizer from "@/util/NumberHumanizer";
import QMath from "@/libraries/QMath";

export default {
    props: {
        stats: {
            description: "Assets stats",
        },
        cstats: {
            description: "Contract stats",
        },
    },
    components: { InnerDashCard },
    methods: {
        ch: CurrencyHumanizer,
        nh: NumberHumanizer,
        perc: QMath.perc,
        i18perc: QMath.i18perc,
    },
};
</script>

<template>
    <div>
        <pre v-if="false">{{ stats }}</pre>
        <div class="row">
            <!-- Box: MW from wind -->
            <div class="col-12 col-md-6 col-lg-3">
                <inner-dash-card
                    :title="$t('reports.mw_wind')"
                    icon="far fa-wind-turbine"
                >
                    <template slot="default">{{
                        stats.wind_rating | numanize
                    }}</template>
                    <template slot="action">MW</template>
                    <template slot="footer"
                        ><i class="far fa-chart-pie-alt"></i>
                        {{
                            $t("reports.N_of_total_X", {
                                N: i18perc(
                                    stats.wind_rating,
                                    parseFloat(stats.wind_rating) +
                                        parseFloat(stats.solar_rating)
                                ),
                                X: nh(
                                    parseFloat(stats.wind_rating) +
                                        parseFloat(stats.solar_rating)
                                ),
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>
            <!-- Box: MW from solar -->
            <div class="col-12 col-md-6 col-lg-3">
                <inner-dash-card
                    :title="$t('reports.mw_solar')"
                    icon="far fa-solar-panel"
                >
                    <template slot="default">{{
                        stats.solar_rating | numanize
                    }}</template>
                    <template slot="action">MW</template>
                    <template slot="footer"
                        ><i class="far fa-chart-pie-alt"></i>
                        {{
                            $t("reports.N_of_total_X", {
                                N: i18perc(
                                    stats.solar_rating,
                                    parseFloat(stats.wind_rating) +
                                        parseFloat(stats.solar_rating)
                                ),
                                X: nh(
                                    parseFloat(stats.wind_rating) +
                                        parseFloat(stats.solar_rating)
                                ),
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>
            <!-- Box: asset value - wind -->
            <div class="col-12 col-md-6 col-lg-3">
                <inner-dash-card
                    :title="$t('reports.value_wind')"
                    icon="far fa-wind-turbine"
                >
                    <template slot="default">{{
                        ch(stats.wind_value, stats.wind_value_currency)
                    }}</template>
                    <template slot="footer"
                        ><i class="far fa-chart-pie-alt"></i>
                        {{
                            $t("reports.N_of_total_X", {
                                N: i18perc(
                                    stats.wind_value,
                                    parseFloat(stats.wind_value) +
                                        parseFloat(stats.solar_value)
                                ),
                                X: ch(
                                    parseFloat(stats.wind_value) +
                                        parseFloat(stats.solar_value),
                                    stats.wind_value_currency
                                ),
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>
            <!-- Box: asset value - solar -->
            <div class="col-12 col-md-6 col-lg-3">
                <inner-dash-card
                    :title="$t('reports.value_solar')"
                    icon="far fa-solar-panel"
                >
                    <template slot="default">{{
                        ch(stats.solar_value, stats.solar_value_currency)
                    }}</template>
                    <template slot="footer"
                        ><i class="far fa-chart-pie-alt"></i>
                        {{
                            $t("reports.N_of_total_X", {
                                N: i18perc(
                                    stats.solar_value,
                                    parseFloat(stats.wind_value) +
                                        parseFloat(stats.solar_value)
                                ),
                                X: ch(
                                    parseFloat(stats.wind_value) +
                                        parseFloat(stats.solar_value),
                                    stats.wind_value_currency
                                ),
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <h3>{{$t('reports.power_by_asset_type')}}</h3>
                <div style="display: block; height: 350px" class="mt-4">
                    <apexchart
                        height="100%"
                        :options="{
                            chart: {
                                type: 'donut',
                            },
                            labels: [
                                $t('reports.wind_rating'),
                                $t('reports.solar_rating'),
                            ],
                            yaxis: {
                                labels: {
                                    formatter: function (value) {
                                        return nh(value) + 'MW';
                                    },
                                },
                            },

                            responsive: [
                                {
                                    breakpoint: 480,
                                    options: {
                                        chart: {
                                            width: '100%',
                                        },
                                        legend: {
                                            position: 'bottom',
                                        },
                                    },
                                },
                            ],
                        }"
                        :series="[
                            parseFloat(stats.wind_rating),
                            parseFloat(stats.solar_rating),
                        ]"
                    ></apexchart>
                </div>
            </div>
            <div class="col-6">
                <h3>{{$t('reports.value_by_asset_type')}}</h3>
                <div style="display: block; height: 350px" class="mt-4">
                    <apexchart
                        height="100%"
                        :options="{
                            chart: {
                                type: 'donut',
                            },
                            labels: [
                                $t('reports.wind_value'),
                                $t('reports.solar_value'),
                            ],
                            yaxis: {
                                labels: {
                                    formatter: function (value) {
                                        return ch(
                                            value,
                                            stats.wind_value_currency
                                        );
                                    },
                                },
                            },

                            responsive: [
                                {
                                    breakpoint: 480,
                                    options: {
                                        chart: {
                                            width: '100%',
                                        },
                                        legend: {
                                            position: 'bottom',
                                        },
                                    },
                                },
                            ],
                        }"
                        :series="[
                            parseFloat(stats.wind_value),
                            parseFloat(stats.solar_value),
                        ]"
                    ></apexchart>
                </div>
            </div>
        </div>
        <!-- .row -->
    </div>
</template>

<style>
</style>