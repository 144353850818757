<script>
/**
 * This is the new contract report
 * JRI / 2024-09-20
 */

import VGlobalStats from "@/views/Reports/Contracts/components/VGlobalStats";
import VStatExport from "@/views/Reports/Contracts/components/VStatExport";
import VContractStats from "@/views/Reports/Contracts/components/VContractStats";
import VContractDetails from "@/views/Reports/Contracts/components/VContractDetails";
import VAssets from "@/views/Reports/Contracts/components/VAssets";
import VAssetsDetails from "@/views/Reports/Contracts/components/VAssetsDetails";
import VProductionTurnover from "@/views/Reports/Contracts/components/VProductionTurnover";
import VAssetsByCountries from "@/views/Reports/Contracts/components/VAssetsByCountries";
import VAssetsBySegment from "@/views/Reports/Contracts/components/VAssetsBySegment";
import VAssetsPerCountry from "@/views/Reports/Contracts/components/VAssetsPerCountry";

import AssetService from "@/services/AssetService";
import CurrencyService from "@/services/CurrencyService";
import ContractService from "@/services/ContractService";

export default {
    name: "VReports",

    components: {
        VGlobalStats,
        VAssets,
        VProductionTurnover,
        VAssetsDetails,
        VContractStats,
        VContractDetails,
        VStatExport,
        VAssetsBySegment,
        VAssetsByCountries,
        VAssetsPerCountry
    },
    data() {
        return {
            loading: false,
            loadingCS: true,
            loadingCD: true,
            loadingAC: true,
            cs: null,
            cd: null,
            ac: null,
            currency: "DKK",
            currencies: [],

            // per segment
            hasC_DES: false,
            hasC_MEGS: false,
            cStatsDES: [],
            cStatsMEGS: []
        };
    },
    watch: {
        currency: function() {
            this.loadReports();
        }
    },
    async created() {
        this.loading = true;
        await this.fetchCurrencies();
        await this.loadReports();
        this.loading = false;
    },
    methods: {
        /**
         * Load assets by country
         */
        async fetchAssetsByCountry() {
            try {
                this.loadingAC = true;
                const { data } = await AssetService.GET_statsByCountry();
                this.ac = data.data;
            } catch (e) {}
            this.loadingAC = false;
        },
        /**
         * Stats for custom tables in the top
         */

        async loadReports() {
            this.loadingCS = true;
            this.loadingCD = true;
            this.loadingAC = true;
            await this.fetchCS();
            await this.fetchCD();
            await this.fetchAssetsByCountry();
        },
        /**
         * Get currency list
         */
        async fetchCurrencies() {
            try {
                const r = await CurrencyService.dropdownCodes({
                    fview: "active"
                });
                this.currencies = r.data.data;
            } catch (e) {}
        },
        /**
         * All other statistics excl. stat groups
         */
        async fetchCS() {
            try {
                this.loadingCS = true;
                const r = await ContractService.GET_statistics({
                    currency: this.currency
                });
                this.cs = r.data.data;
            } catch (e) {
                console.log("fetchCS exception", e);
            }

            this.loadingCS = false;
        },
        /**
         * Money elements like total turnover, quarterly, per aseet type and per contract type
         */
        async fetchCD() {
            try {
                this.loadingCD = true;
                const r = await ContractService.GET_statContractDetails({
                    currency: this.currency
                });
                this.cd = r.data.data;
            } catch (e) {
                console.log("fetchCD exception", e);
            }

            this.loadingCD = false;
        }
    }
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>

        <div class="container-fluid">
            <v-loader :loading="loading" v-if="loading" />
            <div class="row card-wrapper" v-else>
                <div class="col-12 d-flex">
                    <h1>{{ $t("reports.statistics") }}</h1>
                </div>

                <!-- Top tables DK DE MEG -->
                <v-assets-by-countries :cs="cs" />

                <!-- Top tables per segment DK DE MEG -->
                <v-assets-by-segment :cs="cs" />

                <template v-if="false">
                    <v-loader :loading="loadingCS" v-if="loadingCS" />
                    <template v-else>
                        <pre>{{ cs }}</pre>
                    </template>
                </template>

                <!-- CONTRACTS -->
                <div class="col-12">
                    <card body-classes="px-0">
                        <h3 class="px-4 mb-4">
                            {{ $t("reports.contracts_dk") }}
                        </h3>
                        <div class="px-4">
                            <v-loader :loading="loadingCS" v-if="loadingCS" />
                            <v-contract-stats
                                :stats="cs.contracts"
                                :cstats="cs.contracts"
                                v-else
                            />
                        </div>
                    </card>
                </div>
                <!-- CONTRACTS -->

                <!-- CONTRACTS DETAILS -->
                <div class="col-12" v-if="true">
                    <card body-classes="px-0">
                        <h3 class="px-4 mb-4">
                            {{ $t("reports.contract_turnover_dk") }}
                        </h3>
                        <div class="px-4">
                            <v-loader :loading="loadingCD" v-if="loadingCD" />
                            <v-contract-details
                                :stats="cd"
                                :currency="currency"
                                v-else
                            />
                        </div>
                    </card>
                </div>
                <!-- CONTRACTS  DETAILS -->

                <!-- CONTRACTS DETAILS -->
                <div class="col-12" v-if="true">
                    <card body-classes="px-0">
                        <h3 class="px-4 mb-4">
                            {{
                                $t("reports.active_distribution_per_country_dk")
                            }}
                        </h3>
                        <div class="px-4">
                            <v-loader :loading="loadingAC" v-if="loadingAC" />
                            <v-assets-per-country :ac="ac" v-else />
                        </div>
                    </card>
                </div>
                <!-- CONTRACTS  DETAILS -->

                <!-- PRODUCTION -->
                <div class="col-12">
                    <card body-classes="px-0">
                        <h3 class="px-4 mb-4">
                            {{ $t("reports.production_and_turnover_dk") }}
                        </h3>
                        <div class="px-4">
                            <v-loader :loading="loadingCS" v-if="loadingCS" />
                            <v-production-turnover :stats="cs.budgets" v-else />
                        </div>
                    </card>
                </div>
                <!-- .PRODUCTION -->

                <!-- ASSETS GROUPED -->
                <div class="col-12">
                    <card body-classes="px-0">
                        <h3 class="px-4 mb-4">
                            {{ $t("reports.assets_overview_dk") }}
                        </h3>
                        <div class="px-4">
                            <v-loader :loading="loadingCS" v-if="loadingCS" />
                            <v-assets-details
                                :stats="cs.assets_grouped"
                                v-else
                            />
                        </div>
                    </card>
                </div>
                <!-- ASSETS GROUPED -->

                <!-- CSV EXPORT -->
                <div class="col-12" v-if="true">
                    <card body-classes="px-0">
                        <h3 class="px-4 mb-4">
                            {{ $t("reports.data_export") }}
                        </h3>
                        <div class="px-4">
                            <v-stat-export />
                        </div>
                    </card>
                </div>
                <!-- CSV EXPORT -->
            </div>
        </div>
    </div>
</template>
