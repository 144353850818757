import Http from "@/libraries/Http"; 

export default {

    /**
     * 
     * @param fview = active | inactive
     */

    async GET_activeCurrencies(params = {}) {
        return Http.get("/v1/currencies/active-currencies", {params: params}); 
    },

    async dropdownCodes(params = {}) {
        return Http.get("/v1/currencies/dropdown-codes", {params: params}); 
    },

    async dropdown(params = {}) {
        return Http.get("/v1/currencies/dropdown", {params: params}); 
    },

    async getCurrencies(params = {}) {
        return Http.get("/v1/currencies", {params: params}); 
    }, 

    async postCurrency(data) {
        return Http.post("/v1/currencies", data);
    },

    async putCurrency(data) {        
        return Http.put(`/v1/currencies/${data.id}`, data);
    }, 
    
}