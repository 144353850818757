<script>
import InnerDashCard from "@/components/Cards/InnerDashCard";
import CurrencyHumanizer from "@/util/MillionHumanizer";
import NumberHumanizer from "@/util/NumberHumanizer";
import QMath from "@/libraries/QMath";
import MGConsts from "@/mixins/MGConsts";

export default {
    name: "VContractDetails",
    props: {
        stats: {},
        currency: {}
    },
    mixins: [MGConsts],
    components: { InnerDashCard },
    methods: {
        ch: CurrencyHumanizer,
        nh: NumberHumanizer,
        perc: QMath.perc,
        i18perc: QMath.i18perc
    }
};
</script>

<template>
    <div>
        <pre v-if="false">{{ stats }}</pre>
        <div class="row">
            <!-- Box 1 -->
            <div class="col-12 col-md-6 col-lg-4">
                <inner-dash-card
                    :title="$t('reports.total_contract_turnover')"
                    icon="far fa-file-signature"
                >
                    <template slot="default">{{
                        ch(stats.total, stats.currency)
                    }}</template>
                    <template slot="footer">
                        {{
                            $t("reports.billable_contract_amount_in_Y", {
                                Y: stats.year
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>

            <!-- Box 2 -->
            <div class="col-12 col-md-6 col-lg-4">
                <inner-dash-card
                    :title="$t('reports.fixed_contracts')"
                    icon="far fa-file-signature"
                >
                    <template slot="default">{{
                        ch(stats.contract_type[1], stats.currency)
                    }}</template>
                    <template slot="footer"
                        ><i class="far fa-chart-pie-alt"></i>
                        {{
                            $t("reports.N_of_total_X", {
                                N: i18perc(stats.contract_type[1], stats.total),
                                X: ch(stats.total, stats.currency)
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>

            <!-- Box 3 -->
            <div class="col-12 col-md-6 col-lg-4">
                <inner-dash-card
                    :title="$t('reports.variable_contracts')"
                    icon="far fa-file-signature"
                >
                    <template slot="default">{{
                        ch(stats.contract_type[2], stats.currency)
                    }}</template>
                    <template slot="footer"
                        ><i class="far fa-chart-pie-alt"></i>
                        {{
                            $t("reports.N_of_total_X", {
                                N: i18perc(stats.contract_type[2], stats.total),
                                X: ch(stats.total, stats.currency)
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>
        </div>
        <!-- .row -->
        <div class="row">
            <div class="col-6">
                <h3>{{ $t("reports.income_by_asset_type") }}</h3>
                <div style="display: block; height: 350px">
                    <apexchart
                        height="100%"
                        :options="{
                            chart: {
                                type: 'donut'
                            },
                            labels: [
                                $t('reports.contract_income_wind') +
                                    ': ' +
                                    $n(stats.asset_type[1], {
                                        style: 'currency',
                                        currency: 'DKK',
                                        currencyDisplay: 'code'
                                    }),
                                $t('reports.contract_income_solar') +
                                    ': ' +
                                    $n(stats.asset_type[2], {
                                        style: 'currency',
                                        currency: 'DKK',
                                        currencyDisplay: 'code'
                                    })
                            ],
                            yaxis: {
                                labels: {
                                    formatter: function(value) {
                                        return ch(value, stats.currency);
                                    }
                                }
                            },
                            responsive: [
                                {
                                    breakpoint: 480,
                                    options: {
                                        chart: {
                                            width: '100%'
                                        },
                                        legend: {
                                            position: 'bottom'
                                        }
                                    }
                                }
                            ]
                        }"
                        :series="Object.values(stats.asset_type)"
                    ></apexchart>
                </div>
            </div>
            <!-- .col-6 -->
            <div class="col-6">
                <h3>{{ $t("reports.income_by_quarter") }}</h3>
                <div style="display: block; height: 350px">
                    <apexchart
                        height="100%"
                        :options="{
                            chart: {
                                type: 'donut'
                            },
                            labels: [
                                $t('reports.quarter_1') +
                                    ': ' +
                                    $n(stats.qs[1], {
                                        style: 'currency',
                                        currency: 'DKK',
                                        currencyDisplay: 'code'
                                    }),
                                $t('reports.quarter_2') +
                                    ': ' +
                                    $n(stats.qs[2], {
                                        style: 'currency',
                                        currency: 'DKK',
                                        currencyDisplay: 'code'
                                    }),
                                $t('reports.quarter_3') +
                                    ': ' +
                                    $n(stats.qs[3], {
                                        style: 'currency',
                                        currency: 'DKK',
                                        currencyDisplay: 'code'
                                    }),
                                $t('reports.quarter_4') +
                                    ': ' +
                                    $n(stats.qs[4], {
                                        style: 'currency',
                                        currency: 'DKK',
                                        currencyDisplay: 'code'
                                    })
                            ],
                            yaxis: {
                                labels: {
                                    formatter: function(value) {
                                        return ch(value, stats.currency);
                                    }
                                }
                            },
                            responsive: [
                                {
                                    breakpoint: 480,
                                    options: {
                                        chart: {
                                            width: '100%'
                                        },
                                        legend: {
                                            position: 'bottom'
                                        }
                                    }
                                }
                            ]
                        }"
                        :series="Object.values(stats.qs)"
                    ></apexchart>
                </div>
            </div>
            <!-- .col-6 -->
        </div>
        <!-- .row -->
        <div class="row mt-5" v-if="false">
            <div class="col-6">
                <h3>
                    {{ $t("reports.yearly_contract_income_in_home_currency") }}
                </h3>
                <ul class="list-group mt-4">
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col text-bold">
                                {{ $t("report.currency") }}
                            </div>
                            <div class="col text-right text-bold">
                                {{ $t("report.amount_in_currency") }}
                            </div>
                            <div class="col text-right text-bold">
                                {{
                                    $t("report.amount_converted_to_X", {
                                        X: currency
                                    })
                                }}
                            </div>
                        </div>
                    </li>
                    <li
                        v-for="(amount, currency) in stats.currencies"
                        :key="currency"
                        class="list-group-item"
                    >
                        <div class="row">
                            <div class="col">
                                <i class="far fa-coins"></i> {{ currency }}
                            </div>
                            <div class="col text-right">
                                {{
                                    $n(amount, {
                                        style: "currency",
                                        currency: currency,
                                        currencyDisplay: "symbol"
                                    })
                                }}
                            </div>
                            <div class="col text-right">
                                {{
                                    $n(stats.currencies_conv[currency], {
                                        style: "currency",
                                        currency: stats.currency,
                                        currencyDisplay: "code"
                                    })
                                }}
                            </div>
                        </div>
                    </li>
                </ul>

                <h4 class="mt-4">
                    {{ $t("reports.distribution_by_currency") }}
                </h4>
                <div class="mt-2" style="display: block; height: 240px">
                    <apexchart
                        height="100%"
                        :options="{
                            chart: {
                                type: 'donut'
                            },
                            labels: Object.keys(stats.currencies_conv),
                            yaxis: {
                                labels: {
                                    formatter: function(value) {
                                        return ch(value, stats.currency);
                                    }
                                }
                            },
                            responsive: [
                                {
                                    breakpoint: 480,
                                    options: {
                                        chart: {
                                            width: '100%'
                                        },
                                        legend: {
                                            position: 'bottom'
                                        }
                                    }
                                }
                            ]
                        }"
                        :series="Object.values(stats.currencies_conv)"
                    ></apexchart>
                </div>
            </div>
            <div class="col-6">
                <h3>{{ $t("reports.yearly_invoice_plan") }}</h3>
                <div class="table-responsive">
                    <v-table class="table table-sm table-striped">
                        <v-thead>
                            <v-tr>
                                <v-th>{{ $t("reports.invoice_date") }}</v-th>
                                <v-th class="text-right">{{
                                    $t("reports.amount")
                                }}</v-th>
                            </v-tr>
                        </v-thead>
                        <v-tbody>
                            <v-tr
                                v-for="(amount, date) in stats.days"
                                :key="date"
                            >
                                <v-td>{{ $d(new Date(date), "short") }}</v-td>
                                <v-td class="text-right">{{
                                    $n(amount, {
                                        style: "currency",
                                        currency: stats.currency,
                                        currencyDisplay: "code"
                                    })
                                }}</v-td>
                            </v-tr>
                        </v-tbody>
                    </v-table>
                </div>
            </div>
        </div>
        <!-- .row -->
    </div>
</template>

<style></style>
